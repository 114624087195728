var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainContainer" } },
    [
      _c(
        "b-button",
        {
          staticClass: "btn btn-light ",
          attrs: { id: "carPasses" },
          on: {
            click: function($event) {
              return _vm.goToCarPasses()
            }
          }
        },
        [_vm._v("Przepustki samochodowe")]
      ),
      _c("Logout"),
      _c("header", { attrs: { id: "title" } }, [_vm._v("Przepustki osobowe")]),
      _c(
        "b-form",
        {
          staticClass: "needs-validation",
          attrs: { id: "form", novalidate: "" }
        },
        [
          _c("table", [
            _c("tr", [
              _c(
                "td",
                [
                  _c("vue-typeahead-bootstrap", {
                    staticClass: "input",
                    class: [
                      { valid: _vm.nameState },
                      { invalid: _vm.nameState == false }
                    ],
                    attrs: {
                      data: _vm.nameData,
                      state: false,
                      "aria-invalid": "true",
                      placeholder: "Imię: ",
                      required: ""
                    },
                    model: {
                      value: _vm.pass.name,
                      callback: function($$v) {
                        _vm.$set(_vm.pass, "name", $$v)
                      },
                      expression: "pass.name"
                    }
                  })
                ],
                1
              ),
              _c("td", { staticClass: "info" }, [
                _c("p", { class: { colRed: _vm.nameCurrentLength > 20 } }, [
                  _vm._v(" " + _vm._s(_vm.nameCurrentLength) + "/20 ")
                ])
              ])
            ]),
            _c(
              "td",
              [
                _c("vue-typeahead-bootstrap", {
                  staticClass: "input",
                  class: [
                    { valid: _vm.lastnameState },
                    { invalid: _vm.lastnameState == false }
                  ],
                  attrs: {
                    state: _vm.lastnameState,
                    data: _vm.lastnameData,
                    placeholder: "Nazwisko: ",
                    required: ""
                  },
                  model: {
                    value: _vm.pass.lastname,
                    callback: function($$v) {
                      _vm.$set(_vm.pass, "lastname", $$v)
                    },
                    expression: "pass.lastname"
                  }
                })
              ],
              1
            ),
            _c("td", { staticClass: "info" }, [
              _c("p", { class: { colRed: _vm.lastnameCurrentLength > 20 } }, [
                _vm._v(" " + _vm._s(_vm.lastnameCurrentLength) + "/20 ")
              ])
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("vue-typeahead-bootstrap", {
                    staticClass: "input",
                    class: [
                      { valid: _vm.companyState },
                      { invalid: _vm.companyState == false }
                    ],
                    attrs: {
                      state: _vm.companyState,
                      data: _vm.companyData,
                      placeholder: "Firma: ",
                      required: ""
                    },
                    model: {
                      value: _vm.pass.company,
                      callback: function($$v) {
                        _vm.$set(_vm.pass, "company", $$v)
                      },
                      expression: "pass.company"
                    }
                  })
                ],
                1
              ),
              _c("td", { staticClass: "info" }, [
                _c("p", { class: { colRed: _vm.companyCurrentLength > 20 } }, [
                  _vm._v(" " + _vm._s(_vm.companyCurrentLength) + "/20 ")
                ])
              ])
            ]),
            _c(
              "tr",
              [
                _c("b-form-datepicker", {
                  staticClass: "mb-2 input",
                  attrs: {
                    state: _vm.validFromState,
                    placeholder: "Ważne od",
                    required: ""
                  },
                  model: {
                    value: _vm.pass.validFrom,
                    callback: function($$v) {
                      _vm.$set(_vm.pass, "validFrom", $$v)
                    },
                    expression: "pass.validFrom"
                  }
                })
              ],
              1
            ),
            _c(
              "tr",
              [
                _c("b-form-datepicker", {
                  staticClass: "mb-2 input",
                  attrs: {
                    state: _vm.validToState,
                    placeholder: "Ważne do",
                    required: ""
                  },
                  model: {
                    value: _vm.pass.validTo,
                    callback: function($$v) {
                      _vm.$set(_vm.pass, "validTo", $$v)
                    },
                    expression: "pass.validTo"
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { attrs: { id: "buttons" } },
        [
          _c(
            "b-button",
            {
              attrs: { id: "addButton", variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.addPass()
                }
              }
            },
            [_vm._v("Dodaj przepustkę")]
          ),
          _c(
            "b-button",
            {
              attrs: { id: "deleteButton", variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.deletePasses()
                }
              }
            },
            [_vm._v("Usuń zaznaczone")]
          ),
          _c(
            "b-button",
            {
              attrs: { id: "printButton", variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.printPass()
                }
              }
            },
            [_vm._v("Wygeneruj zaznaczone")]
          ),
          _c(
            "b-button",
            {
              attrs: { id: "copyButton", variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.copyPass()
                }
              }
            },
            [_vm._v("Kopiuj zaznaczone")]
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "list" } },
        [
          _c("b-table", {
            ref: "selectableTable",
            attrs: {
              selectable: "",
              "select-mode": _vm.selectMode,
              striped: "",
              hover: "",
              items: _vm.passesArray,
              bordered: true,
              fields: _vm.fields,
              responsive: "sm"
            },
            on: { "row-selected": _vm.onRowSelected },
            scopedSlots: _vm._u([
              {
                key: "cell(selected)",
                fn: function(ref) {
                  var rowSelected = ref.rowSelected
                  return [
                    rowSelected
                      ? [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("✓")
                          ]),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Selected")
                          ])
                        ]
                      : [
                          _c("span", { attrs: { "aria-hidden": "true" } }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Not selected")
                          ])
                        ]
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }