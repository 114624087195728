var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "form" } }, [
      _c("table", [
        _c("tr", [
          _c(
            "td",
            [
              _c("b-form-input", {
                staticClass: "input",
                attrs: {
                  type: "number",
                  state: _vm.idNumberState,
                  placeholder: "Numer identyfikatora parkingowego: "
                },
                model: {
                  value: _vm.pass.idNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.pass, "idNumber", $$v)
                  },
                  expression: "pass.idNumber"
                }
              })
            ],
            1
          ),
          _c("td", { staticClass: "info" }, [
            _c("p", { class: { colRed: _vm.idNumberCurrentLength > 4 } }, [
              _vm._v(" " + _vm._s(_vm.idNumberCurrentLength) + "/4 ")
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { attrs: { id: "buttons" } },
        [
          _c(
            "b-button",
            {
              attrs: { id: "addButton" },
              on: {
                click: function($event) {
                  return _vm.addPass()
                }
              }
            },
            [_vm._v("Dodaj przepustkę")]
          ),
          _c(
            "b-button",
            {
              attrs: { id: "deleteButton" },
              on: {
                click: function($event) {
                  return _vm.deletePasses()
                }
              }
            },
            [_vm._v("Usuń zaznaczone")]
          ),
          _c(
            "b-button",
            {
              attrs: { id: "printButton" },
              on: {
                click: function($event) {
                  return _vm.print()
                }
              }
            },
            [_vm._v("Wygeneruj zaznaczone")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { attrs: { id: "list" } },
      [
        _c("b-table", {
          ref: "selectableTable",
          attrs: {
            selectable: "",
            "select-mode": _vm.selectMode,
            striped: "",
            hover: "",
            items: _vm.passesArray,
            bordered: true,
            fields: _vm.fields,
            responsive: "sm"
          },
          on: { "row-selected": _vm.onRowSelected },
          scopedSlots: _vm._u([
            {
              key: "cell(selected)",
              fn: function(ref) {
                var rowSelected = ref.rowSelected
                return [
                  rowSelected
                    ? [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("✓")
                        ]),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Selected")
                        ])
                      ]
                    : [
                        _c("span", { attrs: { "aria-hidden": "true" } }),
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Not selected")
                        ])
                      ]
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }