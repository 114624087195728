import { render, staticRenderFns } from "./Choose.vue?vue&type=template&id=63ace971&scoped=true&"
import script from "./Choose.vue?vue&type=script&lang=js&"
export * from "./Choose.vue?vue&type=script&lang=js&"
import style0 from "./Choose.vue?vue&type=style&index=0&id=63ace971&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ace971",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Marta\\Desktop\\przepustki\\przepustki\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63ace971')) {
      api.createRecord('63ace971', component.options)
    } else {
      api.reload('63ace971', component.options)
    }
    module.hot.accept("./Choose.vue?vue&type=template&id=63ace971&scoped=true&", function () {
      api.rerender('63ace971', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Views/Choose.vue"
export default component.exports