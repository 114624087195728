var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "logout" } },
    [
      _c(
        "b-button",
        {
          staticClass: "btn btn-light ",
          on: {
            click: function($event) {
              return _vm.signOut()
            }
          }
        },
        [_vm._v("Wyloguj")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }