var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainContainer" } },
    [
      _c(
        "b-button",
        {
          staticClass: "btn btn-light ",
          attrs: { id: "personalPasses" },
          on: {
            click: function($event) {
              return _vm.goToPersonalPasses()
            }
          }
        },
        [_vm._v("Przepustki osobowe")]
      ),
      _c("Logout"),
      _c(
        "header",
        {
          class: {
            titleP: _vm.typeP,
            titleD: _vm.typeD,
            titleN: _vm.typeN,
            titleG: _vm.typeG
          },
          attrs: { id: "title" }
        },
        [_vm._v(" Przepustki samochodowe ")]
      ),
      _c("b-container", { attrs: { id: "passType" } }, [
        _c("p", [_vm._v("Wybierz rodzaj przepustki:")]),
        _c("div", { staticClass: "radio-toolbar" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            attrs: { id: "P", value: "P", type: "radio" },
            domProps: { checked: _vm._q(_vm.selectedType, "P") },
            on: {
              change: function($event) {
                _vm.selectedType = "P"
              }
            }
          }),
          _c("label", { attrs: { id: "PLabel", for: "P" } }, [_vm._v("P")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            attrs: { id: "D", value: "D", type: "radio" },
            domProps: { checked: _vm._q(_vm.selectedType, "D") },
            on: {
              change: function($event) {
                _vm.selectedType = "D"
              }
            }
          }),
          _c("label", { attrs: { id: "DLabel", for: "D" } }, [_vm._v("D")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            attrs: { id: "N", value: "N", type: "radio" },
            domProps: { checked: _vm._q(_vm.selectedType, "N") },
            on: {
              change: function($event) {
                _vm.selectedType = "N"
              }
            }
          }),
          _c("label", { attrs: { id: "NLabel", for: "N" } }, [_vm._v("N")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedType,
                expression: "selectedType"
              }
            ],
            attrs: { id: "G", value: "G", type: "radio" },
            domProps: { checked: _vm._q(_vm.selectedType, "G") },
            on: {
              change: function($event) {
                _vm.selectedType = "G"
              }
            }
          }),
          _c("label", { attrs: { id: "GLabel", for: "G" } }, [_vm._v("G")])
        ])
      ]),
      _vm.selectedType === "N" ||
      _vm.selectedType === "D" ||
      _vm.selectedType === "P"
        ? _c("NDPForm")
        : _vm._e(),
      _vm.selectedType === "G" ? _c("GForm") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }