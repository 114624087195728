var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainContainer" } },
    [
      _c("Logout"),
      _c("h2", { attrs: { id: "title" } }, [
        _vm._v("Wybierz rodzaj przepustki:")
      ]),
      _c("router-link", { attrs: { to: "przepustki-osobowe" } }, [
        _c("div", { attrs: { id: "personal" } }, [
          _c("img", { attrs: { src: require("../assets/people.png") } }),
          _c("p", [_vm._v("Przepustki osobowe")])
        ])
      ]),
      _c("router-link", { attrs: { to: "przepustki-samochodowe" } }, [
        _c("div", { attrs: { id: "car" } }, [
          _c("img", { attrs: { src: require("../assets/car.png") } }),
          _c("p", [_vm._v("Przepustki samochodowe")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }