import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./custom.scss";
import firebase from "firebase/app";
import store from "./store";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

const firebaseConfig = {
  apiKey: "AIzaSyDXmkLSyhR7a3T6XILYxIsicKQeKMYZzKM",
  authDomain: "przepustki-87705.firebaseapp.com",
  databaseURL: "https://przepustki-87705.firebaseio.com",
  projectId: "przepustki-87705",
  storageBucket: "przepustki-87705.appspot.com",
  messagingSenderId: "1030518712423",
  appId: "1:1030518712423:web:d2908271dfcf84a8f54cc2"
};

firebase.initializeApp(firebaseConfig);

Vue.use(BootstrapVue, IconsPlugin);
Vue.config.productionTip = false;

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

let app;
firebase.auth().onAuthStateChanged(user => {
  user;
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});
